
import { Form, Input, Switch, message } from "antd"
import { useEffect, useState } from "react"
import { Button } from "antd"
import { connect } from "react-redux"
import { Menu as MenuAction } from "../../store/global/actions"
import "./PhoneSimulator.css"


function HealthGuidance(props) {
    let [data, setData] = useState(null)
    let [channel, setCannel] = useState(null)

    props.setActiveKey("SETTING-HEALTH-GUIDANCE")

    useEffect(
        () => {
            const _channel = props.socket.channel(`operation:settings:healthGuidance`)
            _channel.join()
            _channel.on("data", ({ data }) => {
                console.log((data[0] || { content: "{}" }).content)
                setData((data[0] || { content: "{}" }).content)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, [])

    const onSave = (values) => {
        channel.push("set", { healthGuidance: JSON.stringify(values) })
            .receive("ok", () => { message.success({ content: "保存成功" }) })
    }

    return <div className="page settings" style={{ flexDirection: "column", display: "flex", height: "100%" }}>
        <h1>健康咨询设置</h1>

        <div style={{ display: "flex", flexGrow: 1 }}>
            {!!data &&
                <Form style={{ width: 300 }} initialValues={JSON.parse(data)} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" onFinish={onSave}>
                    <Form.Item label="是否开启" name="enable" valuePropName="checked" rules={[{ required: true, message: '不能为空' }]}>
                        <Switch />
                    </Form.Item>
                    <Form.Item label="微信号" name="wechat_id" rules={[{ required: true, message: '不能为空' }]} >
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ xs: { span: 8, offset: 8, } }}>
                        <Button.Group>
                            <Button size="small" type="primary" htmlType="submit">保存</Button>
                        </Button.Group>
                    </Form.Item>
                </Form>
            }
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthGuidance)