import React from "react"
import { Menu as MenuAction } from '../store/global/actions'
import { Radio, DatePicker, Table, Button, Select, Form, InputNumber, Input, Switch, Modal, ConfigProvider, Alert, message } from "antd"
import { CheckCircleOutlined, CheckOutlined, FormOutlined, DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons'
import PasswordConfirm from "../components/passwordConfirm";
import dayjs from "dayjs"
// import ButtonGroup from "antd/lib/button/button-group";

import { connect } from "react-redux"
import zhCN from 'antd/lib/locale/zh_CN';
import "./Pricing.css"

const { Column } = Table;
class Pricing extends React.Component {
    // 渠道价格视图的表单引用
    channelPricingViewFormRef = React.createRef()
    state = {
        channel: null,
        // 当前类别的销售价格目录
        pricings: [],
        channels: [],
        versions: [],
        // 销售活动数据
        salesData: {},
        topTabKey: "sales",
        // 渠道价格列查价弹窗
        channelPricingViewModalOpen: false,
        // 渠道价格列表，用于查价
        channelPricings: [],
        // 渠道价格视图表单数据
        channelPricingsForm: { channel_code: "HUAWEI", active_at: dayjs() },
        query: this.defaultFilter(this.props.match?.params?.meta)
    }

    componentDidMount() {
        this.props.setActiveKey("FINANCE")

        let channel = this.props.socket.channel("pricing:setting", this.state.query)
        channel.on("data", ({ data }) => {
            this.setState({ pricings: data })
        })
        channel.on("channel-data", ({ data }) => {
            this.setState({ channels: data })
        })
        channel.on("version-data", ({ data }) => {
            let channelPricingsForm = { ...this.state.channelPricingsForm, version_code: data[0]?.code }

            this.setState({ versions: data, channelPricingsForm })
        })
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        if (this.state.channel)
            this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.match?.params?.meta !== this.state.query.meta) {
            this.handleQuery("meta", props.match?.params?.meta)
        }
    }

    handleQuery(field, value) {
        if (this.state.query[field] !== value) {

            this.setState({ query: { ...this.state.query, [field]: value } }, () => {
                this.state.channel.push("query", this.state.query)
            })
        }
    }

    // 处理获取渠道金额
    handleGetChannelPricings(params) {
        this.setState({ channelPricingsForm: params })
        this.state.channel.push("channel-pricings", { channel: params.channel_code, active_at: params.active_at, version_code: params.version_code })
            .receive("ok", ({ data }) => {
                this.setState({ channelPricings: data })
            })
    }

    defaultFilter(meta) {
        switch (meta) {
            case "acupoint:shelve::vip":
                return {
                    meta: meta,
                    group: "vip",
                    active_at: null,
                    version_code: 0,
                    channel: null
                }
            case "acupoint:shelve::video_course":
                return {
                    meta: meta,
                    group: "vip",
                    active_at: null,
                    version_code: 0,
                    channel: null
                }

            default:
                return {};
        }
    }

    filterView() {

        switch (this.state.query.meta) {
            case "acupoint:shelve::vip":
                return <>
                    <Radio.Group buttonStyle="solid" size="small" value={this.state.query.group} onChange={(e) => this.handleQuery("group", e.target.value)}>
                        <Radio.Button value="vip">普通价格</Radio.Button>
                        <Radio.Button value="newcomer_vip">潜客价格</Radio.Button>
                    </Radio.Group>
                    <p></p>
                </>
            case "acupoint:shelve::video_course":
                return <>
                    <Radio.Group buttonStyle="solid" size="small" value={this.state.query.group} onChange={(e) => this.handleQuery("group", e.target.value)}>
                        <Radio.Button value="vip">会员价格</Radio.Button>
                        <Radio.Button value="potential">潜客价格</Radio.Button>
                    </Radio.Group>
                    <p></p>
                </>

            default:
                break;
        }
    }

    typeView(value) {
        switch (value) {
            case "duration":
                return "期限"
            case "single":
                return "单次"
            case "period":
                return "连续付费"
            default:
                break;
        }
    }

    unitLabel(unitMeta) {
        let dic = { "acupoint:trade:unit::day": "天", "acupoint:trade:unit::month": "月", "acupoint:trade:unit::year": "年", "acupoint:trade:unit::lifelong": "终身" }
        return dic[unitMeta]
    }

    editFormRef = React.createRef()
    // 修改甲木
    handleEditForm = (item) => {
        const currentEvidence = JSON.parse(localStorage.getItem('current-evidence') || 'null')
        Modal.confirm({
            width: 500,
            title: `编辑价目:${item.name}`,
            content: <ConfigProvider locale={zhCN}>
                <Form ref={this.editFormRef} name="basic" initialValues={item} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
                    <Form.Item label="内部名" name="name" rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder="如: 第387号计划实施Avalon" />
                    </Form.Item>
                    <Form.Item label="展示名" name="display_name" rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder="如: 终身卡" />
                    </Form.Item>
                    <Form.Item label="小标题" name="slogan">
                        <Input placeholder="如: 最有人气" />
                    </Form.Item>
                    <Form.Item label="售价" name="amount" rules={[{ required: true, message: '必填' }]} help="注意单位是人民币分">
                        <InputNumber min={1} addonAfter="分" />
                    </Form.Item>
                    <Form.Item label="原价" name="origin_amount" rules={[{ required: true, message: '必填' }]} help="注意单位是人民币分">
                        <InputNumber min={1} addonAfter="分" />
                    </Form.Item>
                    <Form.Item label="换算价格" name={["content", "price_convert", "price"]} help="注意单位是人民币分">
                        <InputNumber addonBefore={<Form.Item noStyle name={["content", "price_convert", "unit"]}>
                            <Select defaultValue="acupoint:trade:unit::month">
                                <Select.Option value="acupoint:trade:unit::day">日均</Select.Option>
                                <Select.Option value="acupoint:trade:unit::month">月均</Select.Option>
                                <Select.Option value="acupoint:trade:unit::year">年均</Select.Option>
                            </Select>
                        </Form.Item>} min={1} addonAfter="分" />
                    </Form.Item>
                    <Form.Item label="数量" name="num" rules={[{ required: true, message: '必填' }]} >
                        <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item label="单位" name="unit_meta" rules={[{ required: true, message: '必填' }]} >
                        <Select>
                            <Select.Option value="acupoint:trade:unit::day">天</Select.Option>
                            <Select.Option value="acupoint:trade:unit::month">月</Select.Option>
                            <Select.Option value="acupoint:trade:unit::year">年</Select.Option>
                            <Select.Option value="acupoint:trade:unit::lifelong">永久</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="组内排序" name="order" rules={[{ required: true, message: '必填' }]} help="正序排序">
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item label="支付方式" name={["data", "trade_channels"]} rules={[{ required: true, message: '必填' }]} help="第一个是默认支付方式">
                        <Select mode="multiple">
                            <Select.Option key="acupoint:trade:channel::wechat" value="acupoint:trade:channel::wechat">微信</Select.Option>
                            <Select.Option key="acupoint:trade:channel::alipay" value="acupoint:trade:channel::alipay">支付宝</Select.Option>
                            <Select.Option key="acupoint:trade:channel::apple" value="acupoint:trade:channel::apple">苹果</Select.Option>
                        </Select>
                    </Form.Item>

                    <Alert message="你的操作会被记录在案" banner type="warning" showIcon />
                    <Form.Item label="账号">
                        <span className="ant-form-text">{currentEvidence.account}</span>
                    </Form.Item>
                    <Form.Item label="确认密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                        <Input.Password />
                    </Form.Item>
                </Form>
            </ConfigProvider>,
            cancelText: "取消",
            okText: "提交",
            onOk: (close) => {
                const form = this.editFormRef.current
                form
                    .validateFields()
                    .then(values => {
                        this.state.channel.push("edit-basic", { ...values, id: item.id })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    channelSaveForm = React.createRef()
    // 保存渠道价目
    handleChannelSaveForm = (pricing, item) => {
        item = { ...item, after_user_active_at: item.after_user_active_at ? dayjs(item.after_user_active_at) : null }
        const currentEvidence = JSON.parse(localStorage.getItem('current-evidence') || 'null')
        Modal.confirm({
            width: 600,
            title: !!item ? `编辑 ${item.channel_code} 渠道价格` : `新增渠道价格`,
            content: <ConfigProvider locale={zhCN}>
                <Form ref={this.channelSaveForm} name="basic" initialValues={item} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
                    <Form.Item label="渠道" name="channel_code" rules={[{ required: true, message: '必填' }]}>
                        <Select>
                            {
                                this.state.channels.map(item => <Select.Option label={item.name} key={item.key} value={item.key}></Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="展示名" name="display_name" rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder="如: 终身卡" />
                    </Form.Item>
                    <Form.Item label="小标题" name="slogan">
                        <Input placeholder="如: 最有人气" />
                    </Form.Item>
                    <Form.Item label="售价" name="amount" rules={[{ required: true, message: '必填' }]} help="注意单位是人民币分">
                        <InputNumber min={1} addonAfter="分" />
                    </Form.Item>
                    <Form.Item label="换算价格" name={["content", "price_convert", "price"]} help="注意单位是人民币分">
                        <InputNumber addonBefore={<Form.Item noStyle name={["content", "price_convert", "unit"]}>
                            <Select defaultValue="acupoint:trade:unit::month">
                                <Select.Option value="acupoint:trade:unit::day">日均</Select.Option>
                                <Select.Option value="acupoint:trade:unit::month">月均</Select.Option>
                                <Select.Option value="acupoint:trade:unit::year">年均</Select.Option>
                            </Select>
                        </Form.Item>} min={1} addonAfter="分" />
                    </Form.Item>
                    <Form.Item label="数量" name="num" rules={[{ required: true, message: '必填' }]} >
                        <InputNumber min={1} addonAfter={this.unitLabel(pricing.unit_meta)} />
                    </Form.Item>
                    <Form.Item label="最小支持版本" name="min_version_code" rules={[{ required: true, message: '必填' }]} help="小于此版本的用户则使用默认价格">
                        <Select>
                            <Select.Option value={0}>不限制</Select.Option>
                            {
                                this.state.versions.map(item => <Select.Option key={item.key} value={item.code}>{item.name}({item.code})</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="激活有效日期" name="after_user_active_at" help="晚于此日期激活的用户生效">
                        <DatePicker format="YYYY-MM-DD HH:mm" showTime />
                    </Form.Item>
                    <Form.Item label="支付方式" name={["data", "trade_channels"]} rules={[{ required: true, message: '必填' }]} help="第一个是默认支付方式">
                        <Select mode="multiple">
                            <Select.Option key="acupoint:trade:channel::wechat" value="acupoint:trade:channel::wechat">微信</Select.Option>
                            <Select.Option key="acupoint:trade:channel::alipay" value="acupoint:trade:channel::alipay">支付宝</Select.Option>
                            <Select.Option key="acupoint:trade:channel::apple" value="acupoint:trade:channel::apple">苹果</Select.Option>
                        </Select>
                    </Form.Item>
                    <Alert message="你的操作会被记录在案" banner type="warning" showIcon />
                    <Form.Item label="账号">
                        <span className="ant-form-text">{currentEvidence.account}</span>
                    </Form.Item>
                    <Form.Item label="确认密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                        <Input.Password />
                    </Form.Item>
                </Form>
            </ConfigProvider>,
            cancelText: "取消",
            okText: "提交",
            onOk: (close) => {
                const form = this.channelSaveForm.current
                form
                    .validateFields()
                    .then(values => {
                        this.state.channel.push("channel-save", { ...values, id: item?.id, pricing_id: pricing.id })
                            .receive("ok", (resp) => {
                                close()
                                message.success("保存渠道价格成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    dropChannelPasswordConfirmRef = React.createRef()
    handleDropChannel = (channel_id) => {
        PasswordConfirm({
            ref: this.dropChannelPasswordConfirmRef,
            title: '密码确认',
            message: "删除渠道会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                this.state.channel.push("channel-drop", { id: channel_id, password }, 100000)
                    .receive("ok", () => {
                        message.success({ content: '已删除渠道', duration: 3 })
                        close()
                    })
            }
        })
    }

    // 修改字段
    handleSetField = (id, field, value) => {
        this.state.channel.push("edit-field", { id, field, value })
    }

    // 修改字段
    handleSetChannelField = (id, field, value) => {
        this.state.channel.push("channel-edit-field", { id, field, value })
    }

    // 修改字段
    handleSetGroupDefault = (id) => {
        this.state.channel.push("set-group-default", { id })
    }


    render() {
        return <div className="page pricing" >
            <h1>价目表</h1>
            <div className="filter">
                {this.filterView()}
                <Button onClick={() => {
                    this.handleGetChannelPricings(this.state.channelPricingsForm)
                    this.setState({ channelPricingViewModalOpen: true })
                }}>渠道价格视图</Button>
                <Modal title="渠道价格视图" open={this.state.channelPricingViewModalOpen} onCancel={() => this.setState({ channelPricingViewModalOpen: false })} footer={false}>
                    <Form ref={this.channelPricingViewFormRef} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} initialValues={this.state.channelPricingsForm} onValuesChange={(_, allValues) => this.handleGetChannelPricings(allValues)}>
                        <Form.Item label="渠道" name="channel_code">
                            <Select showSearch>
                                {
                                    this.state.channels.map(item => <Select.Option label={item.name} key={item.key} value={item.key}>{item.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="版本" name="version_code">
                            <Select>
                                {
                                    this.state.versions.map(item => <Select.Option key={item.key} value={item.code}>{item.name}({item.code})</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="激活时间" name="active_at">
                            <DatePicker format="YYYY-MM-DD HH:mm" showTime></DatePicker>
                        </Form.Item>
                    </Form>

                    <Table dataSource={this.state.channelPricings} size="middle">
                        <Column title="展示名" dataIndex="display_name" key="display_name" />
                        <Column title="售价" dataIndex="amount" key="amount" width={100} render={text => <span style={{ color: "green" }}>{text / 100} 元</span>} />
                        <Column title="原价" dataIndex="originAmount" key="originAmount" render={text => <span style={{ color: "#aaa" }}>{text / 100} 元</span>} />
                        <Column title="小标题" dataIndex="slogan" key="slogan" />
                    </Table>
                </Modal>
            </div>
            <p></p>
            {this.state.pricings.length > 0 && <Table dataSource={this.state.pricings}
                // defaultExpandAllRows
                rowClassName="version-row"
                size="middle"
                expandable={{
                    rowExpandable: (item) => (item?.channels || []).length > 0,
                    expandedRowRender: (pricing) => {
                        return <div style={{ padding: "20px 0 20px 32px" }} key={pricing.id}>
                            <Table dataSource={pricing.channels} pagination={false} bordered rowKey={item => item.id}>
                                <Column title="渠道" dataIndex="channel_code" key="channel_code" width={150} align="center" />
                                <Column title="展示名" dataIndex="display_name" key="display_name" />
                                <Column title="小标题" dataIndex="slogan" key="slogan" />
                                <Column title="组内排序" dataIndex="order" key="order" align="center" width={120} sorter={{ compare: (a, b) => a.order - b.order, multiple: 1 }} />
                                <Column title="最小版本号" dataIndex="min_version_code" key="min_version_code" width={120} align="center" render={(v) => {
                                    if (v === 0) return <span style={{ color: "#aaa" }}>任意版本</span>
                                    let version = this.state.versions.find(({ code }) => code === v)
                                    return `${version?.name}(${version?.code || v})`
                                }} />
                                <Column title="售价" dataIndex="amount" key="amount" width={100} render={text => <span style={{ color: "green" }}>{text / 100} 元</span>} />
                                <Column title="换算价" dataIndex={["content", "price_convert"]} key="price_convert" render={(text, v) => <span style={{ color: "#aaa" }}>{v.content?.price_convert?.price ? `${this.unitLabel(v.content?.price_convert?.unit)}付 ${v.content?.price_convert?.price / 100}元` : ''}</span>} />
                                <Column title="激活时间有效" dataIndex="after_user_active_at" key="after_user_active_at" width={110} render={(text) => !!text && dayjs(`${text}`).add(8, "hour").format("YYYY-MM-DD")} />
                                <Column title="数量" align="center" dataIndex="num" key="num" width={80} />
                                <Column title="状态" dataIndex="status" key="status" width={130} render={(value, item) => <Radio.Group onChange={(e) => this.handleSetChannelField(item.id, "status", e.target.value)} size="small" value={value} buttonStyle="solid">
                                    <Radio.Button value="disabled">禁用</Radio.Button>
                                    <Radio.Button value="enable">启用</Radio.Button>
                                </Radio.Group>} />
                                <Column title="操作" width={230} render={(text, item) => <>
                                    <Button size="small" type="link" icon={<EditOutlined />} onClick={() => this.handleChannelSaveForm(pricing, item)}>编辑渠道</Button>
                                    <Button size="small" type="link" danger icon={<DeleteOutlined />} onClick={() => this.handleDropChannel(item.id)}>删除渠道</Button>

                                </>} />
                            </Table>
                        </div>
                    }
                }}
                pagination={false} rowKey={d => d.id}>
                <Column title="ID" dataIndex="id" key="id" sorter={(a, b) => a.id - b.id} />
                <Column title="组内排序" dataIndex="order" key="order" sorter={{ compare: (a, b) => a.order - b.order, multiple: 1 }} />
                <Column title="价格名" dataIndex="name" key="name" />
                <Column title="展示名" dataIndex="display_name" key="display_name" />
                <Column title="小标题" dataIndex="slogan" key="slogan" />
                {/* <Column title="组内默认" dataIndex="group_default" key="group_default" /> */}
                <Column title="售价" dataIndex="amount" key="amount" render={text => <span style={{ color: "green" }}>{text / 100} 元</span>} />
                <Column title="原价" dataIndex="origin_amount" key="origin_amount" render={text => <span style={{ color: "#aaa" }}>{text / 100} 元</span>} />
                <Column title="换算价" dataIndex={["content", "price_convert"]} key="price_convert" render={(text, v) => <span style={{ color: "#aaa" }}>{v.content?.price_convert?.price ? `${this.unitLabel(v.content?.price_convert?.unit)}付 ${v.content?.price_convert?.price / 100}元` : ''}</span>} />
                <Column title="计费类型" align="center" dataIndex="type" key="type" render={this.typeView.bind(this)} />
                <Column title="数量" align="center" dataIndex="num" key="num" />
                <Column title="单位" dataIndex="unit_meta" key="unit_meta" render={(v, item) => this.unitLabel(v)} />
                <Column title="组内默认" align="center" dataIndex="group_default" key="group_default" render={(v, item) => <Switch size="small" onChange={(v) => this.handleSetGroupDefault(item.id)} checked={v}></Switch>} />
                <Column title="状态" dataIndex="status" key="status" width={130} render={(value, item) => <Radio.Group onChange={(e) => this.handleSetField(item.id, "status", e.target.value)} size="small" value={value} buttonStyle="solid">
                    <Radio.Button value="disabled">禁用</Radio.Button>
                    <Radio.Button value="enable">启用</Radio.Button>
                </Radio.Group>} />
                <Column title="操作" width={230} render={(text, item) => <>
                    <Button size="small" type="link" icon={<EditOutlined />} onClick={() => this.handleEditForm(item)}>编辑</Button>
                    <Button size="small" type="link" icon={<PlusSquareOutlined />} onClick={() => this.handleChannelSaveForm(item, {})}>新增渠道</Button>
                </>} />
                {/* <Column align="center" title="编辑" key="btn" render={(_any, item) => <Button type="link">编辑</Button>} /> */}
            </Table>
            }
        </div >
    }
}
const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Pricing)